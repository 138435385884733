import { combineReducers } from "@reduxjs/toolkit";
import workerSlice from "../slice/workerSlice";
import userSlice from "../slice/userSlice";
import companySlice from "../slice/companySlice";

const rootReducer = combineReducers({
  worker: workerSlice,
  user: userSlice,
  company: companySlice,
});

export default rootReducer;
