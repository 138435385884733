import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InformationCircle from "../../icons/InformationCircle";
import UserAdd from "../../icons/UserAdd";
import Users from "../../icons/Users";
import CompanyIcon from "../../icons/Home";
import AddCompanyIcon from "../../icons/Plus";
import ClipBoardList from "../../icons/ClipboardList";
import Search from "../../icons/Search";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import logo from "../../assets/hrs logo.png";

const DashboardSidebar = (props) => {
  const accountType = JSON.parse(localStorage.getItem("userData")).user_type;

  let sections;

  if (parseInt(accountType) === 1) {
    sections = [
      {
        title: "Workers",
        items: [
          {
            title: "Add",
            path: "/dashboard/addworker",
            icon: <UserAdd fontSize="small" />,
          },
          {
            title: "View or Search",
            path: "/dashboard/workers",
            icon: <Search fontSize="small" />,
          },
        ],
      },
      {
        title: "Management",
        items: [
          {
            title: "Users",
            icon: <Users fontSize="small" />,
            path: "/dashboard/users",
            children: [
              {
                title: "Add User",
                icon: <UserAdd fontSize="small" />,
                path: "/dashboard/adduser",
              },
              {
                title: "User List",
                icon: <ClipBoardList fontSize="small" />,
                path: "/dashboard/users",
              },
            ],
          },
          {
            title: "Company",
            icon: <CompanyIcon fontSize="small" />,
            path: "/dashboard/companies",
            children: [
              {
                title: "Add Company",
                icon: <AddCompanyIcon fontSize="small" />,
                path: "/dashboard/addcompany",
              },
              {
                title: "Company List",
                icon: <ClipBoardList fontSize="small" />,
                path: "/dashboard/companies",
              },
            ],
          },
        ],
      },
      {
        title: "Visa Expiry",
        items: [
          {
            title: "Visa Expiry Notification",
            path: "/dashboard/visaexpiry",
            icon: <InformationCircle fontSize="small" />,
          },
        ],
      },
    ];
  }

  if (parseInt(accountType) !== 1) {
    sections = [
      {
        title: "Workers",
        items: [
          {
            title: "Add",
            path: "/dashboard/addworker",
            icon: <UserAdd fontSize="small" />,
          },
          {
            title: "View or Search",
            path: "/dashboard/workers",
            icon: <Search fontSize="small" />,
          },
        ],
      },
      {
        title: "Visa Expiry",
        items: [
          {
            title: "Visa Expiry Notification",
            path: "/dashboard/visaexpiry",
            icon: <InformationCircle fontSize="small" />,
          },
        ],
      },
    ];
  }

  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  // useEffect(() => {
  //   if (openMobile && onMobileClose) {
  //     onMobileClose();
  //   }
  // }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
            pb: 0,
          }}
        >
          <RouterLink to="/">
            <img style={{ width: "150px" }} src={logo} alt="gl hrs logo" />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                Global Links BD SDN BHD
              </Typography>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                sx={{ fontSize: 13 }}
              >
                1138430-W
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
