import { Box, Typography } from "@material-ui/core";
import React from "react";

const AdminGuardMessage = () => {
  return (
    <Box sx={{ textAlign: "center", pt: "200px" }}>
      <Typography color="#ef4444" variant="h3">
        You cannot enter this page as you are a normal user.
      </Typography>
    </Box>
  );
};

export default AdminGuardMessage;
