import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from "@material-ui/core";
import LoginForm from "../../components/authentication/login/LoginForm";
import logo from "../../assets/hrs logo.png";

const Login = () => {
  return (
    <>
      <Helmet>
        <title>Login | Global Links</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 8,
            }}
          >
            <img style={{ width: "300px" }} src={logo} alt="gl hrs logo" />
          </Box>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Log in
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {<LoginForm />}
              </Box>

              {/* <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/authentication/password-recovery"
                variant="body2"
              >
                Forgot password
              </Link> */}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
