import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  loading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUsers: (state, action) => {
      return (state = { ...state, users: action.payload });
    },
    setLoading: (state, action) => {
      return (state = { ...state, loading: action.payload });
    },
  },
});

export const { addUsers, setLoading } = userSlice.actions;
export const selectUserData = (state) => state.user;

export default userSlice.reducer;
