import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companies: [],
  loading: false,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    addCompanies: (state, action) => {
      return (state = { ...state, companies: action.payload });
    },
    setLoading: (state, action) => {
      return (state = { ...state, loading: action.payload });
    },
  },
});

export const { addCompanies, setLoading } = companySlice.actions;
export const selectUserData = (state) => state.company;

export default companySlice.reducer;
