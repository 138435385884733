import { Suspense, lazy } from "react";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";
import AdminGuard from "./components/AdminGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const VerifyCode = Loadable(
  lazy(() => import("./pages/authentication/VerifyCode"))
);

// Main page
const Main = Loadable(lazy(() => import("./pages/Main")));

// Dashboard pages

const Workers = Loadable(lazy(() => import("./pages/dashboard/Workers")));
const VisaExpiry = Loadable(lazy(() => import("./pages/dashboard/VisaExpiry")));
const AddUser = Loadable(lazy(() => import("./pages/dashboard/AddUser")));
const Users = Loadable(lazy(() => import("./pages/dashboard/Users")));
const AddWorker = Loadable(lazy(() => import("./pages/dashboard/AddWorker")));
const WorkerDetails = Loadable(
  lazy(() => import("./pages/dashboard/common/WorkerDetails"))
);
const WorkerUpdate = Loadable(
  lazy(() => import("./pages/dashboard/common/WorkerUpdate"))
);
const Companies = Loadable(
  lazy(() => import("./pages/dashboard/company/Companies"))
);
const UpdateCompany = Loadable(
  lazy(() => import("./pages/dashboard/company/UpdateCompany"))
);
const AddCompany = Loadable(
  lazy(() => import("./pages/dashboard/company/AddCompany"))
);
// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

const routes = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "register-unguarded",
        element: <Register />,
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Workers />,
      },
      {
        path: "addworker",
        element: <AddWorker />,
      },
      {
        path: "workers",
        element: <Workers />,
      },
      {
        path: "workers/:id",
        element: <WorkerDetails />,
      },
      {
        path: "workers/edit/:id",
        element: <WorkerUpdate />,
      },
      {
        path: "addcompany",
        element: <AddCompany />,
      },
      {
        path: "companies",
        element: <Companies />,
      },
      {
        path: "companies/edit/:id",
        element: <UpdateCompany />,
      },
      {
        path: "adduser",
        element: (
          <AdminGuard>
            <AddUser />
          </AdminGuard>
        ),
      },
      {
        path: "users",
        element: (
          <AdminGuard>
            <Users />
          </AdminGuard>
        ),
      },
      {
        path: "visaexpiry",
        element: <VisaExpiry />,
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
